import { getPublicEnvVars } from '../env.lib';

const env = getPublicEnvVars();

const FOLHA_PLATFORM_URLS = {
  development: 'http://local-atreus.qconcursos-hmg.com:3000',
  staging: 'https://folha.qconcursos.com',
  production: 'https://folha.qconcursos.com'
} as const;

const MESA_PLATFORM_URLS = {
  development: 'https://app.qconcursos-hmg.com',
  staging: 'https://app.qconcursos-hmg.com',
  production: 'https://app.qconcursos.com'
} as const;

const ODIN_PLATFORM_URLS = {
  development: 'http://localhost:3000',
  staging: 'https://beta.qconcursos-hmg.com',
  production: 'https://www.qconcursos.com'
} as const;

const ASSETS_URLS = {
  development: 'https://assets.qconcursos-hmg.com',
  staging: 'https://assets.qconcursos-hmg.com',
  production: 'https://odin.qconcursos.com'
} as const;

const CNU_URLS = {
  development:
    'https://www.qconcursos.com/l/concurso-nacional-unificado?qtm_source=qconcursos&qtm_campaign=metodoq-2024&qtm_medium=top-menu&qtm_content=concurso-unificado',
  staging:
    'https://www.qconcursos.com/l/concurso-nacional-unificado?qtm_source=qconcursos&qtm_campaign=metodoq-2024&qtm_medium=top-menu&qtm_content=concurso-unificado',
  production:
    'https://www.qconcursos.com/l/concurso-nacional-unificado?qtm_source=qconcursos&qtm_campaign=metodoq-2024&qtm_medium=top-menu&qtm_content=concurso-unificado'
} as const;

export const FOLHA_PLATFORM_URL = FOLHA_PLATFORM_URLS[env.PUBLIC_APP_ENV];
export const MESA_PLATFORM_URL = MESA_PLATFORM_URLS[env.PUBLIC_APP_ENV];
export const ODIN_PLATFORM_URL = ODIN_PLATFORM_URLS[env.PUBLIC_APP_ENV];
export const ASSETS_URL = ASSETS_URLS[env.PUBLIC_APP_ENV];
export const CNU_URL = CNU_URLS[env.PUBLIC_APP_ENV];
